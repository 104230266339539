.myButton {
  background: #FE5900;
  border-radius: 5rem;
  height: 3rem;
  width: 9rem;
  border: 0;
  outline: none;
  overflow: hidden;
  position: relative; }
  .myButton span {
    font-size: 0.8rem;
    font-weight: 700;
    line-height: 1rem;
    color: white; }
  .myButton:nth-child(2) {
    border: 3px solid #367782 !important;
    margin-left: 1rem;
    background: white; }
    .myButton:nth-child(2) span {
      color: #367782; }

.myButton2 {
  background: #FE5900;
  border-radius: 5rem;
  height: 3rem;
  width: 9rem;
  border: 0;
  outline: none;
  overflow: hidden;
  position: relative; }
  .myButton2 span {
    font-size: 0.8rem;
    font-weight: 700;
    line-height: 1rem;
    color: white;
    position: relative;
    z-index: 2; }
  .myButton2:nth-child(2) {
    background: white;
    color: #367782;
    border: 3px solid #367782 !important;
    margin-left: 1rem; }
    .myButton2:nth-child(2) span {
      color: white; }

.myButton3 {
  background: transparent;
  color: #367782;
  border: 3px solid #367782 !important;
  border-radius: 5rem;
  padding: 0.85rem 1.2rem;
  position: relative;
  overflow: hidden; }
  .myButton3 span {
    font-size: 1rem;
    line-height: 1rem;
    color: #367782;
    z-index: 2;
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-family: 'Roboto', sans-serif; }
  .myButton3 svg {
    fill: #367782;
    height: 0.55rem;
    width: 1.6rem;
    margin-left: 0.5rem; }

.myButton4 {
  color: #367782;
  border: 3px solid #367782 !important;
  border-radius: 5rem;
  padding: 0.85rem 1.2rem;
  position: relative;
  overflow: hidden;
  background: transparent; }
  .myButton4 span {
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    line-height: 1rem;
    color: white;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center; }
  .myButton4 svg {
    fill: white;
    height: 0.55rem;
    display: flex;
    width: 1.6rem;
    margin-left: 0.5rem; }

.buttonWrap {
  display: block;
  margin: auto; }

html,
body {
  font-family: 'Bitter', serif !important;
  overflow-x: hidden !important; }

@media screen and (min-width: 1921px) {
  html,
  body {
    font-size: 20px; } }

@media screen and (max-width: 1920px) and (min-width: 1681px) {
  html,
  body {
    font-size: 20px; } }

@media screen and (max-width: 1680px) and (min-width: 1600px) {
  html,
  body {
    font-size: 20px; } }

@media screen and (max-width: 1599px) and (min-width: 1360px) {
  html,
  body {
    font-size: 16px; } }

@media screen and (max-width: 1359px) and (min-width: 1024px) {
  html,
  body {
    font-size: 15px; } }

@media screen and (max-width: 1023px) and (min-width: 768px) {
  html,
  body {
    font-size: 15px; } }

@media screen and (max-width: 767px) and (min-width: 320px) {
  html,
  body {
    font-size: 14px; } }

button:focus {
  outline: none !important;
  box-shadow: none !important; }

.universalTitle {
  padding-top: 5rem; }
  .universalTitle h3 {
    text-align: center;
    font-size: 1.8rem;
    line-height: 2.2rem;
    font-weight: 700;
    color: #3A3A3A;
    position: relative;
    z-index: 1;
    margin: 0;
    margin-bottom: 3.5rem; }
    .universalTitle h3:after {
      content: '';
      width: calc(100% + 1.2rem);
      position: absolute;
      bottom: 0;
      left: -0.6rem;
      background: #EBEFF1;
      height: 0.8rem;
      z-index: -1; }
    .universalTitle h3:before {
      content: '';
      height: 30px;
      width: 2px;
      position: absolute;
      bottom: calc(100% + 0.7rem);
      left: 0;
      background: orange;
      right: 0;
      margin: auto; }

.universalTitle2 {
  padding-top: 3.5rem; }
  .universalTitle2 > div {
    float: left;
    margin-bottom: 1rem; }
    .universalTitle2 > div h3 {
      text-align: left;
      font-size: 1.8rem;
      line-height: 2.2rem;
      font-weight: 700;
      color: #3A3A3A;
      position: relative;
      z-index: 1;
      margin: 0; }
      .universalTitle2 > div h3:after {
        content: '';
        width: calc(100% + 1.2rem);
        position: absolute;
        bottom: 0;
        left: -0.6rem;
        background: #EBEFF1;
        height: 0.8rem;
        z-index: -1; }
      .universalTitle2 > div h3:last-child {
        float: left; }
    .universalTitle2 > div:after {
      clear: both; }

.universalTitle3 {
  padding-top: 0; }
  .universalTitle3 > div {
    float: left;
    margin-bottom: 2rem; }
    .universalTitle3 > div h3 {
      text-align: left;
      font-size: 1.8rem;
      line-height: 2.2rem;
      font-weight: 700;
      color: #3A3A3A;
      position: relative;
      z-index: 1;
      margin: 0;
      float: left; }
      .universalTitle3 > div h3:after {
        content: '';
        width: calc(100% + 1.2rem);
        position: absolute;
        bottom: 0;
        left: -0.6rem;
        background: #EBEFF1;
        height: 0.8rem;
        z-index: -1; }
    .universalTitle3 > div:after {
      clear: both; }
  .universalTitle3 h6 {
    color: #858585;
    font-weight: 500;
    line-height: 1.5rem;
    font-size: 0.9rem;
    font-family: 'Roboto', sans-serif;
    margin: 0; }
  .universalTitle3 p {
    color: #858585;
    font-weight: 300;
    line-height: 1.5rem;
    font-size: 0.9rem;
    font-family: 'Roboto', sans-serif;
    margin: 0; }

.background {
  background: url("images/bg.png");
  background-repeat: no-repeat;
  background-size: cover; }

@media screen and (max-width: 1920px) and (min-width: 1681px) {
  .background {
    background-size: contain; } }

@media screen and (max-width: 767px) and (min-width: 300px) {
  .background {
    background: none; } }

.eventsPage a {
  text-decoration: none; }

.pagination .all .allLink {
  background-color: #E5E5E5;
  border-radius: 6px;
  margin-left: 0.5rem;
  padding: 0.6rem 0.85rem 0.55rem 0.85rem;
  color: #1F1A16;
  font-weight: 700;
  text-decoration: none;
  font-size: 0.8rem; }

.pagination .active .allLink {
  background-color: #FE5900;
  color: #FFFFFF !important;
  text-decoration: none; }

.pagination .break-me a {
  color: black;
  background-color: #E5e5e5;
  border: 5px;
  padding: 0.4rem 0.55rem 0.50rem 0.55rem;
  border-radius: 6px;
  margin-left: 0.5rem;
  text-decoration: none;
  font-size: 1rem; }

.pagination .previous a {
  outline: none;
  background-color: #E5E5E5;
  padding: 0.5rem 0.75rem 0.50rem 0.8rem;
  border-radius: 6px; }
  .pagination .previous a::after {
    content: '';
    border: solid #1F1A16;
    border-width: 0 0.1rem 0.1rem 0;
    display: inline-block;
    padding: 0.2rem;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    position: relative; }

.pagination .next a {
  outline: none;
  background-color: #E5E5E5;
  margin-left: 0.5rem;
  padding: 0.5rem 0.75rem 0.50rem 0.8rem;
  border-radius: 6px; }
  .pagination .next a::after {
    content: '';
    border: solid #1F1A16;
    border-width: 0 0.1rem 0.1rem 0;
    display: inline-block;
    padding: 0.2rem;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    position: relative; }

input:focus,
textarea:focus,
select:focus {
  border: 1px solid #CCD5D9 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: none !important; }

select {
  -webkit-appearance: none;
  -moz-appearance: none; }

.animacija {
  opacity: 1;
  border-radius: 100%;
  background: #367782;
  animation-name: asd;
  animation-duration: 0.7s;
  outline: none;
  width: 800px;
  height: 800px;
  margin-left: -420px;
  margin-top: -450px;
  border-radius: 100%; }

@keyframes asd {
  from {
    width: 0px;
    height: 0px;
    border-radius: 100%;
    margin-left: 0;
    margin-top: 0; }
  to {
    width: 800px;
    height: 800px;
    margin-left: -420px;
    margin-top: -450px;
    border-radius: 100%; } }

.react-datepicker__header {
  background: #367782 !important; }

.react-datepicker__current-month {
  color: white !important; }

.react-datepicker__day-name {
  color: white; }

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range {
  background: #222; }
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover {
    background: #222; }

.react-datepicker .react-datepicker__navigation--next {
  border-left-color: white !important; }

.react-datepicker .react-datepicker__navigation--previous {
  border-right-color: white !important; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: #367782 !important; }

.react-datepicker-wrapper {
  width: 100%;
  margin-bottom: 2.5rem; }
  .react-datepicker-wrapper .react-datepicker__input-container {
    width: 100% !important; }
  .react-datepicker-wrapper input {
    padding: 1rem;
    color: #CCD5D9;
    position: relative; }

@media screen and (max-width: 767px) and (min-width: 0px) {
  .react-datepicker-popper {
    width: 100%; }
    .react-datepicker-popper .react-datepicker {
      width: 100%; }
    .react-datepicker-popper .react-datepicker__month-container {
      float: unset; }
    .react-datepicker-popper .react-datepicker__day-name,
    .react-datepicker-popper .react-datepicker__day,
    .react-datepicker-popper .react-datepicker__time-name {
      width: 2.5rem; } }

.jobs {
  background: #F2F5F6;
  border-radius: 10px;
  padding: 0.8rem;
  margin-bottom: 1.5rem;
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1); }
  .jobs li {
    margin-bottom: 0.5rem;
    font-family: 'Roboto', sans-serif;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.4rem;
    margin-bottom: 0; }
  .jobs h5 {
    font-weight: 600;
    margin-bottom: 0.8rem;
    font-weight: 700;
    color: #367782; }
  .jobs p {
    font-family: 'Roboto', sans-serif;
    font-size: 0.9rem !important;
    font-weight: 400; }

.orangeh6 {
  font-size: 1.3rem;
  color: #FE5900;
  margin-bottom: 1.1rem;
  font-weight: 600;
  margin-top: 3rem; }

@media screen and (max-width: 767px) and (min-width: 0px) {
  .orangeh6 {
    margin-top: 0rem; } }

.required {
  border: 1px solid red !important; }

.canvas-img {
  display: none; }

.download-btn button {
  border: 3px solid #367782 !important;
  background: white !important;
  margin-left: 1rem; }
  .download-btn button span {
    color: #367782; }
    .download-btn button span:hover {
      color: #fff; }

.react-time-picker__wrapper {
  border: 1px solid #ced4da;
  width: 100%;
  height: 3rem;
  justify-content: center;
  display: flex;
  border-radius: .5rem;
  align-items: center; }
  .react-time-picker__wrapper svg {
    display: none; }

.react-time-picker {
  display: flex;
  border-radius: 0.5rem;
  cursor: pointer;
  margin-bottom: 2.5rem; }

.react-time-picker__inputGroup {
  min-width: unset !important;
  flex-grow: unset !important;
  min-width: unset !important;
  border: 0 !important; }
  .react-time-picker__inputGroup:focus {
    border: 0 !important; }
  .react-time-picker__inputGroup .react-time-picker__inputGroup__divider {
    padding: 1px 0.5rem; }
  .react-time-picker__inputGroup .react-time-picker__inputGroup__input {
    min-width: unset !important;
    height: unset !important;
    border: 0 !important;
    margin-bottom: 0 !important;
    text-align: center !important;
    color: black; }
    .react-time-picker__inputGroup .react-time-picker__inputGroup__input:focus {
      border: 0 !important; }

.opening-hours-button button {
  width: 20rem; }

.modal_container {
  width: unset; }

@media screen and (max-width: 767px) and (min-width: 0px) {
  #MaterialTheme {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -150px;
    margin-top: -192px; } }

.preview_container {
  padding: 0 !important; }

.center-align {
  align-items: center !important;
  margin-bottom: 2.5rem; }

.eventsListItem {
  -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
  background: white;
  margin-bottom: 3rem; }
  .eventsListItem:hover {
    transform: scale(1.05);
    transition: all 0.4s ease; }
  .eventsListItem .event-img {
    padding: 0; }
    .eventsListItem .event-img img {
      width: 100%;
      height: 140px;
      object-fit: cover;
      border-bottom-left-radius: 0.5rem;
      border-top-left-radius: 0.5rem; }
      @media only screen and (max-width: 768px) {
        .eventsListItem .event-img img {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0.5rem;
          border-top-right-radius: 0.5rem; } }
  .eventsListItem .futureEventsInformationText {
    text-align: left;
    padding: 1rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem; }
    .eventsListItem .futureEventsInformationText h4 {
      font-size: 1.1rem;
      font-weight: 700;
      line-height: 1.3rem;
      color: #3A3A3A;
      margin-bottom: 0.9rem; }
    .eventsListItem .futureEventsInformationText p {
      margin: 0;
      color: #FE5900;
      font-weight: 1.3rem;
      font-weight: 500;
      font-size: 1rem;
      font-family: 'Roboto', sans-serif; }

.fixed-top {
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 6rem; }
  .fixed-top .nav-item a {
    color: #3A3A3A !important; }
  .fixed-top .facebookIkonica svg,
  .fixed-top .instagramIkonica svg {
    fill: #367782; }
  .fixed-top .header2 img {
    display: none !important; }
  .fixed-top .btn-menu .fa {
    color: black !important; }

.klasa {
  height: 3rem; }

.NavigacijaWrapper {
  margin-top: 1rem; }
  .NavigacijaWrapper svg {
    width: calc(100% + 2rem);
    margin-left: -2rem;
    height: 100%; }
  .NavigacijaWrapper #navbarResponsive {
    justify-content: space-between; }
  .NavigacijaWrapper .navbar {
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
    margin-left: 2.25rem;
    padding: 0; }
    .NavigacijaWrapper .navbar .nav-item {
      list-style: none; }
      .NavigacijaWrapper .navbar .nav-item .nav-link {
        padding: 0;
        font-size: 0.8rem;
        line-height: 1rem;
        font-weight: 700;
        position: relative;
        color: white;
        z-index: 2; }
    .NavigacijaWrapper .navbar .active:after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      background: #FE5900;
      bottom: -1rem;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto; }

.facebookIkonica svg {
  position: absolute;
  top: 2rem;
  right: 4.5rem;
  height: 1.6rem;
  width: 1.6rem;
  z-index: 2;
  fill: white; }

.instagramIkonica svg {
  position: absolute;
  top: 2rem;
  right: 2rem;
  height: 1.6rem;
  width: 1.6rem;
  z-index: 2;
  fill: white; }

@media screen and (max-width: 1300px) and (min-width: 768px) {
  .facebookIkonica {
    display: none; }
  .instagramIkonica {
    display: none; } }

@media screen and (min-width: 992px) {
  .btn-menu {
    display: none; } }

@media screen and (max-width: 991px) and (min-width: 300px) {
  .fixed-top .nav-item a {
    color: white !important; }
  .facebookIkonica {
    display: none; }
  .instagramIkonica {
    display: none; }
  .btn-menu {
    background: none;
    border: 0;
    outline: none;
    z-index: 2;
    display: block;
    position: absolute;
    top: 15px;
    right: 15px; }
    .btn-menu .fa {
      transform: scale(2);
      color: white; }
    .btn-menu:focus {
      outline: none;
      box-shadow: none; }
  #navbarResponsive {
    position: absolute;
    top: 70px;
    left: -225px;
    width: 300px;
    background: #FE5900;
    width: 300px;
    z-index: 5;
    padding: 15px; }
    #navbarResponsive .nav-item {
      text-align: center;
      padding-bottom: 15px; }
      #navbarResponsive .nav-item:last-child {
        padding-bottom: 0; }
      #navbarResponsive .nav-item .nav-link {
        font-size: 1rem; } }

@media screen and (max-width: 991px) and (min-width: 768px) {
  #navbarResponsive {
    right: 15px;
    left: unset; } }

.header .headerBG {
  position: absolute;
  top: 0;
  right: 0;
  object-fit: cover;
  width: calc(50% + 270px); }

.header .headerText {
  padding-top: 7.75rem; }
  .header .headerText h3 {
    font-size: 2.3rem;
    font-weight: 700;
    line-height: 2.7rem;
    color: #367782;
    margin: 0; }
    .header .headerText h3:last-child {
      margin-bottom: 1rem; }
  .header .headerText .orangeText {
    color: #FE5900; }
  .header .headerText p {
    color: #3A3A3A;
    font-size: 0.9rem;
    font-weight: 300;
    line-height: 1.4rem;
    font-family: 'Roboto', sans-serif;
    margin: 0; }
  .header .headerText .buttons {
    margin-top: 2.2rem; }

.header2 {
  padding-bottom: 110px; }

@media screen and (min-width: 1200px) {
  .header2 img {
    width: calc(50% + 315px);
    position: absolute;
    top: 0;
    right: 0; } }

@media screen and (max-width: 1199px) and (min-width: 1024px) {
  .header2 img {
    width: calc(50% + 270px);
    position: absolute;
    top: 0;
    right: 0; }
  .header .headerBG {
    width: calc(50% + 250px); } }

@media screen and (max-width: 1023px) and (min-width: 992px) {
  .header2 img {
    width: calc(50% + 270px);
    position: absolute;
    top: 0;
    right: 0; }
  .header .headerBG {
    width: calc(50% + 230px); } }

@media screen and (max-width: 991px) and (min-width: 768px) {
  .header2 {
    padding-bottom: 60px; }
    .header2 img {
      width: calc(40%);
      position: absolute;
      top: 0;
      right: 0; }
  .header .headerBG {
    width: calc(50% + 120px); } }

@media screen and (max-width: 767px) and (min-width: 300px) {
  .header2 {
    margin-top: 1rem;
    padding-bottom: 40px; }
    .header2 .NavigacijaWrapper {
      margin-top: 0; }
    .header2 img {
      width: calc(55%);
      position: absolute;
      top: 0;
      right: 0; }
  .header .headerBG {
    width: calc(47%);
    z-index: -1; }
  .header .headerText {
    padding-top: 4rem; } }

@media screen and (max-width: 1680px) and (min-width: 1600px) {
  .header .headerBG {
    width: calc(50% + 270px); } }

@media screen and (max-width: 991px) and (min-width: 768px) {
  .header .headerText {
    padding-top: 4rem; } }

.openingHours {
  margin-top: 9rem;
  text-align: center; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .openingHours .fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 0.6;
    animation-duration: 1s; }
  .openingHours svg {
    margin-bottom: 5rem; }
  .openingHours h3 {
    text-align: center;
    font-size: 1.8rem;
    line-height: 2.2rem;
    font-weight: 700;
    color: #3A3A3A;
    position: relative;
    z-index: 1;
    margin: 0;
    margin-bottom: 3.5rem; }
    .openingHours h3:after {
      content: '';
      width: calc(100% + 1.2rem);
      position: absolute;
      bottom: 0;
      left: -0.6rem;
      background: #EBEFF1;
      height: 0.8rem;
      z-index: -1; }
    .openingHours h3:before {
      content: '';
      height: 30px;
      width: 2px;
      position: absolute;
      bottom: calc(100% + 0.7rem);
      left: 0;
      background: orange;
      right: 0;
      margin: auto; }
  .openingHours .workingHoursBox {
    border: 1px solid #367782;
    border-radius: 10px;
    font-family: 'Roboto', sans-serif;
    position: relative;
    height: unset !important;
    overflow: hidden;
    width: 6rem;
    margin: auto; }
    .openingHours .workingHoursBox .boxTop {
      padding: 0.5rem; }
      .openingHours .workingHoursBox .boxTop h4 {
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.3rem;
        color: #367782;
        margin-bottom: 4px; }
      .openingHours .workingHoursBox .boxTop h6 {
        font-size: 0.9rem;
        line-height: 1.2rem;
        font-weight: 400;
        margin: 0; }
        .openingHours .workingHoursBox .boxTop h6 span {
          font-size: 1.1rem;
          font-weight: 700;
          line-height: 1.45rem; }
    .openingHours .workingHoursBox .boxBottom {
      background: #EBEFF1;
      height: 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center; }
      .openingHours .workingHoursBox .boxBottom p {
        text-align: center;
        font-size: 0.7rem;
        font-weight: 300;
        line-height: 0.95rem;
        color: #367782;
        margin: 0;
        width: 100%; }
    .openingHours .workingHoursBox button {
      background: transparent;
      outline: none;
      border: none; }
      .openingHours .workingHoursBox button svg {
        height: 1rem;
        width: 1rem;
        margin-bottom: 0;
        fill: #FE5900 !important; }
  .openingHours .whiteDiv {
    width: 100%;
    background: transparent;
    height: 2rem; }
  .openingHours .carousel-control-prev,
  .openingHours .carousel-control-next {
    bottom: 0.5rem !important;
    top: unset;
    opacity: 1; }
  .openingHours .carousel-control-prev {
    left: 0.8rem; }
  .openingHours .carousel-control-next {
    right: 0.8rem; }
  .openingHours .carousel-control-prev-icon {
    background-image: url("././images/svg/arrow-left-orange.svg") !important;
    height: 1rem;
    width: 1rem; }
  .openingHours .carousel-control-next-icon {
    background-image: url("././images/svg/arrow-right-orange.svg") !important;
    height: 1rem;
    width: 1rem; }
  .openingHours .carousel-item {
    transition: none;
    animation-name: fadeIn; }
  .openingHours .aktivna {
    position: relative;
    background: #367782 !important;
    position: relative;
    max-height: unset;
    width: 8rem;
    overflow: hidden; }
    .openingHours .aktivna h4 {
      color: #FE5900 !important;
      font-size: 1.3rem !important;
      margin-bottom: 1rem !important;
      font-weight: 700 !important; }
    .openingHours .aktivna h6 {
      color: white !important;
      font-size: 1.1rem !important; }
      .openingHours .aktivna h6 span {
        font-size: 1.4rem !important;
        font-weight: 700;
        line-height: 1.9rem !important; }
    .openingHours .aktivna .boxBottom {
      background: #2B616A !important;
      display: flex;
      justify-content: center; }
      .openingHours .aktivna .boxBottom p {
        color: white !important; }

@media screen and (max-width: 991px) and (min-width: 768px) {
  .openingHours {
    margin-top: 4rem; }
    .openingHours .workingHoursBox .boxBottom {
      padding: 0; }
      .openingHours .workingHoursBox .boxBottom p {
        display: inline;
        vertical-align: middle; } }

@media screen and (max-width: 767px) and (min-width: 300px) {
  .openingHours {
    margin-top: 3rem; }
    .openingHours .contents {
      display: contents; }
      .openingHours .contents .workingHoursBox {
        width: 42.5%;
        margin: 5%; }
        .openingHours .contents .workingHoursBox:nth-of-type(even) {
          margin-right: 2.5%; }
        .openingHours .contents .workingHoursBox:nth-of-type(odd) {
          margin-left: 2.5%; }
        .openingHours .contents .workingHoursBox:first-child {
          margin: 5%;
          margin-bottom: 2.5%; }
      .openingHours .contents .aktivna {
        width: 100%; }
      .openingHours .contents .carousel-control-next {
        right: 1rem; }
      .openingHours .contents .carousel-control-prev {
        left: 1rem; } }

.currentInformationBox {
  margin-bottom: 5rem;
  cursor: pointer; }
  .currentInformationBox:hover {
    transform: scale(1.03);
    left: -0.1rem;
    top: -1rem;
    transition: 0.5s;
    position: absolute;
    transition: all 0.4s ease; }
    .currentInformationBox:hover a {
      display: block !important;
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.3rem;
      color: #FE5900;
      font-family: 'Roboto', sans-serif; }
      .currentInformationBox:hover a svg {
        fill: #FE5900;
        height: 0.55rem;
        width: 1.6rem;
        margin-left: 0.5rem; }
  .currentInformationBox .currentInformationImage {
    position: relative; }
    .currentInformationBox .currentInformationImage img {
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      min-height: 200px;
      object-fit: cover;
      width: 100%; }
    .currentInformationBox .currentInformationImage span {
      position: absolute;
      bottom: 10px;
      left: 0;
      font-size: 10px;
      background-color: rgba(240, 240, 240, 0.6);
      font-family: 'Roboto', sans-serif;
      padding: 2px 5px; }
      @media only screen and (max-width: 768px) {
        .currentInformationBox .currentInformationImage span {
          font-size: 9px;
          left: 0; } }
  .currentInformationBox .currentInformationText {
    text-align: center;
    background: white;
    -webkit-box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 1.1rem 3rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem; }
    .currentInformationBox .currentInformationText p {
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.2rem;
      margin: 0;
      text-align: center;
      margin-bottom: 0.8rem; }
    .currentInformationBox .currentInformationText a {
      display: none; }
  .currentInformationBox .teamText {
    text-align: left;
    background: white;
    -webkit-box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 1.1rem 1.1rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem; }
    .currentInformationBox .teamText h3 {
      font-size: 22px;
      line-height: 26px;
      margin-bottom: 7px;
      font-weight: 700; }
    .currentInformationBox .teamText p {
      font-size: 20px;
      color: #FE5900;
      margin-bottom: 0;
      font-family: 'Roboto', sans-serif; }

.allInformationButton {
  display: block !important;
  margin: auto !important; }
  .allInformationButton .btn {
    background: transparent;
    color: #367782;
    border: 3px solid #367782 !important;
    border-radius: 5rem;
    padding: 0.7em 1.2rem;
    font-size: 1rem;
    line-height: 1.3rem;
    font-weight: 500;
    font-family: 'Roboto', sans-serif; }
    .allInformationButton .btn:hover {
      background: none;
      color: #367782; }
    .allInformationButton .btn svg {
      fill: #367782;
      height: 0.55rem;
      width: 1.6rem;
      margin-left: 0.5rem; }

@media screen and (max-width: 767px) and (min-width: 300px) {
  .currentInformationBox {
    margin-bottom: 3rem; }
    .currentInformationBox:hover {
      position: relative !important; }
    .currentInformationBox img {
      min-height: unset !important;
      height: 150px;
      object-fit: contain; }
    .currentInformationBox .teamText h3 {
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 7px;
      font-weight: 700;
      padding: 0; }
    .currentInformationBox .teamText p {
      font-size: 14px;
      color: #FE5900;
      margin-bottom: 0; } }

.futureEventsBox {
  margin-bottom: 3rem; }
  .futureEventsBox:hover {
    transform: scale(1.05);
    transition: all 0.4s ease;
    -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15); }
  .futureEventsBox .futureEventsBoxImage img {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem; }
  .futureEventsBox .futureEventsInformationText {
    text-align: left;
    background: white;
    -webkit-box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem; }
    .futureEventsBox .futureEventsInformationText h4 {
      font-size: 1.1rem;
      font-weight: 700;
      line-height: 1.3rem;
      color: #3A3A3A;
      margin-bottom: 0.9rem; }
    .futureEventsBox .futureEventsInformationText p {
      margin: 0;
      color: #FE5900;
      font-weight: 1.3rem;
      font-weight: 500;
      font-size: 1rem;
      font-family: 'Roboto', sans-serif; }

@media screen and (max-width: 767px) and (min-width: 300px) {
  .futureEventsBox .futureEventsBoxImage img {
    height: 150px;
    object-fit: cover;
    width: 100%; }
  .futureEventsBox h4 {
    font-size: 1rem !important; }
  .futureEventsBox p {
    font-size: 0.8rem !important; } }

.services {
  padding-top: 6rem; }
  .services .contentContainer {
    display: flex;
    padding-bottom: 5rem; }
    .services .contentContainer:last-child {
      padding-bottom: 0; }
  .services .text {
    clear: both;
    padding-right: 0.5rem; }
    .services .text .blueLink {
      font-size: 1rem;
      line-height: 1.3rem;
      font-weight: 500;
      color: #367782;
      font-family: 'Roboto', sans-serif; }
      .services .text .blueLink svg {
        fill: #367782;
        width: 1.6rem;
        height: 0.55rem;
        margin-left: 1rem; }
    .services .text h6 {
      font-size: 0.9rem;
      font-weight: 500;
      line-height: 1.5rem;
      color: #FE5900;
      font-family: 'Roboto', sans-serif;
      margin-bottom: 1.3rem;
      padding-right: 6rem; }
    .services .text p {
      font-size: 0.9rem;
      font-weight: 400;
      line-height: 1.5rem;
      color: #858585;
      font-family: 'Roboto', sans-serif;
      margin-bottom: 2.3rem; }

@media screen and (max-width: 767px) and (min-width: 300px) {
  .services {
    padding-top: 3rem; }
    .services .contentContainer {
      padding-bottom: 2rem; }
      .services .contentContainer .universalTitle2 {
        padding-top: 2rem; }
      .services .contentContainer img {
        padding-top: 1rem; }
    .services .text {
      padding: 0; }
      .services .text h6 {
        padding-right: 0; } }

.map {
  height: 17rem;
  width: 100%;
  margin-top: 5rem;
  border-radius: 10px; }

.contact {
  padding-top: 2.7rem; }
  .contact .form-group input {
    border-radius: 10px;
    height: 3rem;
    margin-bottom: 1rem;
    font-size: 0.9rem;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    color: #A4B7C1; }
  .contact .form-group textarea {
    border-radius: 10px;
    height: 8rem;
    margin-bottom: 1rem;
    font-size: 0.9rem;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    color: #A4B7C1; }

.footer {
  padding-top: 6rem; }
  .footer .blueLink {
    font-size: 1rem;
    line-height: 1.3rem;
    font-weight: 500;
    color: #367782;
    font-family: 'Roboto', sans-serif; }
    .footer .blueLink svg {
      fill: #367782;
      width: 1.6rem;
      height: 0.55rem;
      margin-left: 1rem; }
  .footer .nav-item {
    list-style: none; }
  .footer .blueLink {
    font-size: 0.8rem;
    font-weight: 700;
    line-height: 1rem;
    font-family: 'Bitter', serif; }
  .footer .social {
    float: right;
    margin: 0;
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    line-height: 1.2rem;
    color: #3A3A3A;
    font-family: 'Roboto', sans-serif; }
    .footer .social svg {
      margin-left: 1rem;
      height: 1.6rem;
      width: 1.6rem; }
  .footer .logo svg {
    width: calc(100% + 2rem);
    margin-left: -2rem; }
  .footer .links {
    padding-top: 3rem; }
  .footer .info {
    padding-top: 3rem;
    font-family: 'Bitter', serif; }
    .footer .info .silverLink {
      font-size: 0.9rem;
      line-height: 1.2rem;
      font-weight: 300;
      color: #858585;
      padding-right: 1.5rem; }
  .footer .orangeText {
    font-size: 0.9rem;
    line-height: 1.2rem;
    font-weight: 300;
    color: #FE5900;
    margin: 0; }
  .footer hr {
    border-top: 1px solid #D1DBDC;
    margin-top: 1.5rem;
    margin-bottom: 1.8rem; }
  .footer .copyright {
    font-family: 'Roboto', sans-serif;
    padding-bottom: 2rem; }
    .footer .copyright p {
      font-size: 0.8rem;
      font-weight: 300;
      line-height: 1rem;
      color: #858585;
      margin: 0; }
    .footer .copyright a {
      color: #367782;
      font-size: 0.8rem;
      font-weight: 400;
      line-height: 1rem; }

@media screen and (max-width: 767px) and (min-width: 300px) {
  .footer .logo svg {
    width: 100%;
    margin-left: 0rem; }
  .footer .social {
    float: left;
    padding-top: 2rem; }
  .footer .copyright p {
    margin-bottom: 1em; }
  .footer .links {
    padding-top: 2rem; }
  .footer .info {
    padding-top: 2rem; }
    .footer .info .orangeText {
      padding-top: 1rem; } }

@media screen and (max-width: 991px) and (min-width: 768px) {
  .footer .links .blueLink {
    font-size: 0.7rem; } }

.footerWrapper {
  position: relative; }
  .footerWrapper img {
    position: absolute;
    bottom: 0;
    z-index: -1;
    right: 0; }
  .footerWrapper .bremer {
    position: unset;
    bottom: unset;
    z-index: unset;
    right: unset;
    height: 64px;
    width: 64px;
    margin-left: 16px; }

@media screen and (max-width: 1366px) and (min-width: 768px) {
  .footerWrapper {
    padding-top: 3rem; } }

@media screen and (max-width: 767px) and (min-width: 300px) {
  .footerWrapper img {
    height: calc(100% + 320px);
    width: 100%;
    object-fit: cover; }
  .footerWrapper .footer .copyright {
    padding-bottom: 0; }
  .footerWrapper .footer .links .nav-item {
    padding-bottom: 0.5rem; } }

.aboutPage img {
  margin-bottom: 2rem;
  border-radius: 10px; }

.aboutPage p {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1.1rem;
  color: #3A3A3A;
  line-height: 1.4rem;
  margin-bottom: 1.1rem; }
  .aboutPage p span {
    font-size: 1.1rem;
    font-weight: 700;
    color: #3A3A3A;
    line-height: 1.4rem; }

.aboutPage .priceListPage {
  margin-top: 2rem; }

@media screen and (max-width: 767px) and (min-width: 300px) {
  .aboutPage p {
    text-align: justify; } }

.universalTextPage {
  padding-bottom: 3rem; }
  .universalTextPage h1 {
    font-size: 2.1rem;
    font-weight: 700;
    line-height: 2.7rem;
    color: #367782;
    margin: 0; }
  .universalTextPage .bredkramb {
    display: flex;
    z-index: 2; }
    .universalTextPage .bredkramb .nav-item {
      list-style: none; }
      .universalTextPage .bredkramb .nav-item a {
        z-index: 2;
        font-size: 0.8rem;
        line-height: 1.4rem;
        font-family: 'Roboto', sans-serif;
        color: #3A3A3A;
        font-weight: 300;
        text-decoration: none; }
        .universalTextPage .bredkramb .nav-item a:after {
          content: '/';
          margin-left: 0.5rem;
          margin-right: 0.5rem; }
      .universalTextPage .bredkramb .nav-item:last-child a {
        color: #FE5900; }
        .universalTextPage .bredkramb .nav-item:last-child a::after {
          content: none; }

@media screen and (max-width: 767px) and (min-width: 300px) {
  .bredkramb {
    margin-top: 1rem; }
    .bredkramb .nav-item a {
      font-size: 1rem !important; } }

.eventDetailPage .middle img {
  border-radius: 10px;
  width: 100%; }

.eventDetailPage .middle h4 {
  font-size: 1.6rem;
  line-height: 1.8rem;
  font-weight: 700;
  color: #3A3A3A;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin: 0; }

.eventDetailPage .middle .orangeParagraf {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.3rem;
  color: #FE5900;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 1.3rem; }

.eventDetailPage .middle p {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.4rem;
  color: #3A3A3A; }

.eventDetailPage h3 {
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 1.7rem;
  color: #3A3A3A;
  margin-bottom: 1.4rem; }

.eventDetailPage .futureEventsBox {
  margin-bottom: 2.5rem; }

.eventDetailPage .currentInformationBox {
  margin-bottom: 3.3rem; }
  .eventDetailPage .currentInformationBox:hover {
    position: unset; }
    .eventDetailPage .currentInformationBox:hover .currentInformationText p {
      margin-bottom: 0.8rem; }
  .eventDetailPage .currentInformationBox .currentInformationText {
    padding: 1.1rem 2.5rem; }
    .eventDetailPage .currentInformationBox .currentInformationText p {
      margin: 0; }

.noButtonWrapper .btn {
  display: none; }

@media screen and (max-width: 767px) and (min-width: 300px) {
  .eventDetailPage h3 {
    padding-top: 2rem; } }

.priceListPage .universalTextPage {
  padding-bottom: 3.7rem; }

.priceListPage .italic {
  font-weight: 500;
  font-style: italic;
  margin-top: 1rem;
  margin-bottom: 2.5rem; }

.priceListPage .smallParagraph {
  font-size: 0.8rem; }

.priceListPage .orangeH6 {
  font-size: 1.2rem;
  color: #FE5900;
  font-family: 'Bitter', sans-serif; }

.priceListPage .universalTitle {
  padding-top: 0; }
  .priceListPage .universalTitle h3 {
    margin-bottom: 1.5rem; }
    .priceListPage .universalTitle h3::before {
      display: none; }
  .priceListPage .universalTitle p {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 0.9rem;
    line-height: 1.4rem;
    margin-bottom: 4rem; }

.priceListPage p {
  font-family: 'Roboto', sans-serif;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.4rem;
  color: #3A3A3A; }

.priceListPage h5 {
  font-size: 1.3rem;
  font-weight: 700;
  margin-bottom: 1.1rem;
  color: #367782; }

.priceListPage .marginParagraph {
  position: relative;
  margin-right: -2rem; }

.priceListPage .row .row {
  text-align: right; }

.priceListPage .silverRow {
  background: #F2F5F6;
  border-radius: 10px;
  padding-top: 0.8rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-bottom: 0.3rem;
  margin-bottom: 1.5rem;
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1); }
  .priceListPage .silverRow .row {
    display: flex;
    justify-content: space-between;
    text-align: right;
    height: 100%;
    align-items: flex-end; }
  .priceListPage .silverRow p {
    margin-bottom: 0.5rem; }
    .priceListPage .silverRow p span {
      color: #FE5900; }
  .priceListPage .silverRow [class*='col-'] {
    padding: 0 !important; }
  .priceListPage .silverRow .last-parahraph {
    margin-right: 1rem;
    text-align: right; }

@media screen and (max-width: 767px) and (min-width: 0px) {
  .priceListPage h5 {
    font-size: 1rem; }
  .priceListPage .marginParagraph {
    margin-right: unset; }
  .priceListPage .silverRow {
    padding: 0; }
    .priceListPage .silverRow .last-parahraph {
      margin-right: 0; }
    .priceListPage .silverRow [class*='col-'] {
      padding: 15px !important; }
    .priceListPage .silverRow p {
      font-size: 0.8rem; } }

.react-datepicker-wrapper input {
  color: #3A3A3A; }

.text {
  margin-top: 3rem; }
  .text p span {
    font-size: 1.1rem;
    line-height: 1.4rem;
    color: #FE5900; }
    .text p span a {
      color: #FE5900;
      text-decoration: none; }

.offer {
  margin-top: 2.525rem; }
  .offer h2 {
    font-size: 1.3rem;
    line-height: 1.4rem;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    color: #367782;
    margin-bottom: 1.9rem; }

.last {
  margin-top: 3.7rem; }

.inputi {
  align-items: baseline;
  text-align: start; }
  .inputi .black {
    color: #3A3A3A !important; }
  .inputi p {
    font-size: 1.1rem;
    line-height: 1.4rem; }
  .inputi input {
    width: 100%;
    border-radius: .5rem;
    height: 3rem;
    border: 1px solid #CCD5D9; }
    .inputi input::placeholder {
      font-size: .9rem;
      line-height: 1.2rem;
      color: #CCD5D9;
      min-height: 3rem; }
    .inputi input:focus {
      outline: none;
      -webkit-box-shadow: none !important;
      border: 1px solid #CCD5D9 !important; }
  .inputi .input {
    align-items: baseline; }
    .inputi .input input {
      width: 100px; }
    .inputi .input select {
      width: 100px;
      margin-left: 1rem;
      margin-right: 1rem;
      color: #A4B7C1;
      height: 3rem; }
  .inputi .inputSelect {
    align-items: baseline;
    justify-content: space-between; }
    .inputi .inputSelect select {
      position: relative; }
    .inputi .inputSelect .form-group {
      margin-right: 1rem;
      margin-left: 1rem;
      width: 31.5%; }
      .inputi .inputSelect .form-group:last-child {
        margin-right: 0; }
      .inputi .inputSelect .form-group select {
        width: 100%;
        color: #A4B7C1;
        height: 3rem;
        margin-bottom: 2.5rem;
        border-radius: .5rem; }
        .inputi .inputSelect .form-group select::selection {
          color: red; }
      .inputi .inputSelect .form-group svg {
        position: absolute;
        top: 1rem;
        right: 1rem;
        pointer-events: none;
        height: 0.7rem;
        width: 0.7rem; }
  .inputi .prvi {
    margin: 20px 0;
    align-items: center; }
    .inputi .prvi input {
      margin-left: 1rem;
      margin-right: 1rem;
      margin-bottom: 2.35rem;
      margin-top: 2rem; }
    .inputi .prvi .input-wrap {
      margin-top: 0; }
  .inputi .prviSelect {
    margin-bottom: 3.5rem; }
    .inputi .prviSelect svg {
      position: absolute;
      top: 1rem;
      right: 1.5rem;
      pointer-events: none;
      height: 0.7rem;
      width: 0.7rem; }
  .inputi .wrappInput p {
    width: 175px; }
  .inputi .wrappInput input {
    margin-right: 1rem; }
  .inputi .lastInput .form-group {
    width: 18.7%;
    margin-left: 2.525rem;
    margin-right: 2.525rem; }
    .inputi .lastInput .form-group input {
      width: 100%; }
  .inputi .input-wrap {
    margin-top: 1.5rem;
    display: flex; }
    .inputi .input-wrap span {
      font-size: 0.9rem; }
    .inputi .input-wrap .checkbox {
      border: 1px solid #707070;
      width: 1.2rem;
      height: 1.2rem;
      cursor: pointer;
      border-radius: 4px;
      float: left;
      font-size: 0.8rem;
      position: relative;
      margin-right: 1rem; }
    .inputi .input-wrap .checked:after {
      content: '';
      display: block;
      border-right: 0.15rem solid #367782;
      border-bottom: 0.15rem solid #367782;
      width: 0.4rem;
      height: 0.65rem;
      transform: rotate(45deg);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      position: absolute; }

.multiWrapper {
  margin-top: 2rem;
  width: 100%; }
  .multiWrapper button {
    float: right;
    width: 12.5rem;
    margin: 15px; }

@media screen and (max-width: 767px) and (min-width: 300px) {
  .text {
    margin-top: 0; }
  .inputi p {
    font-size: 0.9rem; }
  .inputi .inputSelect {
    padding-top: 2rem; }
  .checkbox {
    width: 18px !important;
    height: 18px !important; }
    .checkbox .checked:after {
      width: 3px !important;
      height: 7px !important;
      border-right: 2px solid #367782;
      border-bottom: 2px solid #367782; }
  .multiWrapper button {
    float: left !important; } }

.fondInput .lastInput {
  width: 500px; }

.fondInput .input-wrap {
  margin-left: 1rem;
  margin-top: 0.2rem; }
  .fondInput .input-wrap .checkbox {
    border: 1px solid #707070;
    width: 1.2rem;
    height: 1.2rem;
    cursor: pointer;
    border-radius: 4px;
    float: left;
    font-size: 0.8rem;
    position: relative;
    margin-right: 1rem; }
  .fondInput .input-wrap .checked:after {
    content: '';
    display: block;
    border-right: 0.15rem solid #367782;
    border-bottom: 0.15rem solid #367782;
    width: 0.4rem;
    height: 0.65rem;
    transform: rotate(45deg);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    position: absolute; }

.fondInput .black {
  color: #3A3A3A !important; }

.fondInput .form-group input {
  height: 3rem;
  border-radius: .5rem;
  width: 100%;
  border: 1px solid #CCD5D9;
  font-family: 'Roboto', sans-serif; }
  .fondInput .form-group input::placeholder {
    font-size: .9rem;
    line-height: 1.2rem;
    font-family: 'Roboto', sans-serif;
    color: #A4B7C1; }

.fondInput .form-group svg {
  position: absolute;
  top: .8rem;
  right: .5rem;
  pointer-events: none; }

.fondInput .react-time-picker {
  display: flex;
  border-radius: 0.5rem;
  cursor: pointer;
  margin-bottom: 0; }

.fondInput .selectField {
  align-items: baseline;
  justify-content: space-between; }
  .fondInput .selectField .form-group {
    width: 31.6%;
    margin-left: 1rem;
    margin-right: 1rem; }
    .fondInput .selectField .form-group:last-child {
      margin-right: 0 !important; }
    .fondInput .selectField .form-group select {
      border-radius: .5rem;
      height: 3rem;
      width: 100%; }

.fondInput .selectFieldSingle .form-group select {
  border-radius: .5rem;
  height: 3rem;
  width: 100%;
  font-size: .9rem;
  line-height: 1.2rem;
  font-family: 'Roboto', sans-serif;
  color: #A4B7C1; }

.fondInput textarea {
  resize: none;
  height: 270px !important; }
  .fondInput textarea::placeholder {
    font-size: .9rem;
    line-height: 1.2rem;
    font-family: 'Roboto', sans-serif;
    color: #A4B7C1; }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .fondInput .selectField .form-group {
    width: 33%; } }

.border-bottom {
  padding-bottom: 1rem; }
  .border-bottom p {
    text-align: start !important; }

.lostAndFoundPage {
  margin-bottom: 5rem; }
  .lostAndFoundPage p {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 1.1rem;
    color: #3A3A3A;
    line-height: 1.4rem;
    margin-bottom: 1.1rem; }
    .lostAndFoundPage p span {
      font-size: 1.1rem;
      font-weight: 700;
      color: #3A3A3A;
      line-height: 1.4rem; }
    .lostAndFoundPage p a {
      color: #FE5900;
      text-decoration: none;
      font-weight: 500; }
  .lostAndFoundPage .spacer {
    margin-bottom: 1rem; }
  .lostAndFoundPage h6 {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 1.1rem;
    color: #FE5900; }
  .lostAndFoundPage h3 {
    font-size: 1.3rem;
    font-weight: 600; }
  .lostAndFoundPage img {
    margin-top: 2rem;
    width: 100%;
    border-radius: 0.5rem; }
  .lostAndFoundPage .radioButtonWrapper {
    display: flex;
    position: relative;
    justify-content: space-between;
    padding-left: 1.3rem; }
    .lostAndFoundPage .radioButtonWrapper li {
      list-style: none;
      font-size: 1rem;
      line-height: 1.2rem;
      color: #4B4A48;
      outline: none; }
      .lostAndFoundPage .radioButtonWrapper li input {
        outline: none;
        -webkit-appearance: none;
        background-color: #ffffff;
        border: 1px solid #CCCCCC;
        padding: 0.45rem;
        border-radius: 2.5rem;
        display: inline-block;
        position: relative;
        margin-right: 0.5rem; }
      .lostAndFoundPage .radioButtonWrapper li input:checked:after {
        content: ' ';
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 2.5rem;
        position: absolute;
        top: 0.2rem;
        background: #FE5900;
        left: 0.2rem;
        font-size: 1.6rem;
        outline: none; }
      .lostAndFoundPage .radioButtonWrapper li:checked {
        background-color: #e9ecee;
        color: #99a1a7;
        border: 1px solid #adb8c0;
        outline: none;
        -webkit-appearance: none; }
  .lostAndFoundPage .form-group {
    margin-top: 1.5rem; }
    .lostAndFoundPage .form-group input {
      width: 100%;
      border-radius: .5rem;
      height: 3rem;
      border: 1px solid #CCD5D9; }
      .lostAndFoundPage .form-group input::placeholder {
        font-size: .9rem;
        line-height: 1.2rem;
        color: #CCD5D9;
        min-height: 3rem; }
      .lostAndFoundPage .form-group input:focus {
        outline: none;
        -webkit-box-shadow: none !important;
        border: 1px solid #CCD5D9 !important; }
  .lostAndFoundPage .myButton,
  .lostAndFoundPage .myButton2 {
    margin-top: 1rem; }

@media screen and (max-width: 767px) and (min-width: 0px) {
  .lostAndFoundPage .text {
    margin-top: 2rem; } }

.anmietung h5 {
  font-size: 1.3rem;
  font-weight: 700;
  margin-bottom: 2rem;
  color: #367782;
  margin-top: 2rem; }

.anmietung p {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.4rem;
  margin-bottom: 1.1rem;
  color: #3A3A3A; }

.anmietung li {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.4rem;
  margin-bottom: 1.1rem;
  color: #3A3A3A; }

.anmietung .myButton,
.anmietung .myButton2 {
  width: unset;
  padding: 0 1rem; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.offnungszeiten .fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.6;
  animation-duration: 0.6s; }

.offnungszeiten .rowDay .carousel {
  font-family: 'Roboto', sans-serif;
  width: 14.285%; }
  .offnungszeiten .rowDay .carousel:first-child .carousel-item {
    border-left: 1px solid #3A3A3A; }
  .offnungszeiten .rowDay .carousel:last-child .carousel-item {
    border-left: 1px solid #3A3A3A; }
  .offnungszeiten .rowDay .carousel .carousel-item {
    width: 100%;
    transition: none;
    border: 1px solid #3A3A3A;
    border-left: 0; }
    .offnungszeiten .rowDay .carousel .carousel-item .dayBox {
      text-align: center; }
      .offnungszeiten .rowDay .carousel .carousel-item .dayBox p {
        color: #367782; }
      .offnungszeiten .rowDay .carousel .carousel-item .dayBox .dayOfWeek {
        border-bottom: 1px solid #367782; }
        .offnungszeiten .rowDay .carousel .carousel-item .dayBox .dayOfWeek p {
          margin: 0; }
    .offnungszeiten .rowDay .carousel .carousel-item .date {
      text-align: center; }
      .offnungszeiten .rowDay .carousel .carousel-item .date p {
        margin-bottom: 0;
        padding-top: 0.5rem; }
    .offnungszeiten .rowDay .carousel .carousel-item .description {
      text-align: center;
      font-size: 0.7rem;
      padding: 0;
      background: #367782;
      padding: 0.5rem 0;
      margin-bottom: 0;
      color: white !important;
      font-weight: 300;
      margin-top: 1rem; }
    .offnungszeiten .rowDay .carousel .carousel-item .day {
      font-size: 1rem;
      font-weight: 700;
      color: #3A3A3A; }
    .offnungszeiten .rowDay .carousel .carousel-item .time {
      text-align: center;
      margin-bottom: 0.5rem;
      padding-top: 0.5rem; }
      .offnungszeiten .rowDay .carousel .carousel-item .time span {
        font-weight: 700; }
  .offnungszeiten .rowDay .carousel .carousel-control-prev-icon {
    background-image: url("././images/svg/arrow-left-orange.svg") !important;
    height: 0.8rem;
    height: 0.8rem; }
  .offnungszeiten .rowDay .carousel .carousel-control-next-icon {
    background-image: url("././images/svg/arrow-right-orange.svg") !important;
    height: 0.8rem;
    height: 0.8rem; }
  .offnungszeiten .rowDay .carousel .carousel-control-prev {
    left: 0.5rem;
    opacity: 1; }
  .offnungszeiten .rowDay .carousel .carousel-control-next {
    right: 0.5rem;
    opacity: 1; }

@media screen and (max-width: 767px) and (min-width: 0px) {
  .offnungszeiten .rowWrap {
    width: 100%;
    overflow: scroll;
    display: flex; }
    .offnungszeiten .rowWrap .rowDay {
      display: -webkit-inline-box;
      margin: unset; }
      .offnungszeiten .rowWrap .rowDay .no-gutters {
        margin: unset; }
      .offnungszeiten .rowWrap .rowDay .carousel {
        width: 30%; } }
